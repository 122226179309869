import {
  SET_STATES,
  SET_CITIES
} from '../constants';

const initialState = { states: [], cities: [] };

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_STATES:
      return {
        ...state,
        states: action.states
      };
    case SET_CITIES:
      return {
        ...state,
        cities: action.cities
      };
    default:
      return state;
  }
}
