import React, { PureComponent } from 'react';
import {
  Grid, Header
} from 'semantic-ui-react';

import DefaultPage from '../../components/DefaultPage';

class Home extends PureComponent {
  render() {
    return (
      <DefaultPage>
        <Grid.Row
          verticalAlign="middle"
        >
          <Grid.Column
            textAlign="center"
          >
            <Header as="h1" style={{ color: '#fff' }}>Em breve teremos novidades...</Header>
          </Grid.Column>
        </Grid.Row>
      </DefaultPage>
    );
  }
}

export default Home;
