const styles = {
  title: {
    fontFamily: 'Poppins',
    fontSize: 46,
    color: '#fff',
    marginTop: 80
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 26,
    color: '#fff'
  },
  formLabel: {
    color: 'white',
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: 16
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40
  },
  buttonText: {
    fontFamily: 'Poppins',
    fontWeight: 200
  }
};

export default styles;
