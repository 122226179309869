const GET_STATES_SAGA = 'GET_STATES_SAGA';
const GET_CITIES_SAGA = 'GET_CITIES_SAGA';
const SET_STATES = 'SET_STATES';
const SEND_PROSPECT_FORM_SAGA = 'SEND_PROSPECT_FORM_SAGA';
const SET_CITIES = 'SET_CITIES';

export {  //eslint-disable-line
  SET_CITIES,
  SET_STATES,
  GET_STATES_SAGA,
  SEND_PROSPECT_FORM_SAGA,
  GET_CITIES_SAGA
};
