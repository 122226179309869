import React, { PureComponent, Fragment } from 'react';
import {
  Grid, Header, Image, Segment
} from 'semantic-ui-react';

import { Helmet } from 'react-helmet';

import {
  isMobile,
  isIOS,
  isAndroid
} from 'react-device-detect';

import DefaultPage from '../../components/DefaultPage';

import styles from './styles';

const appleStoreLogo = require('../../assets/appleStore.png');
const googlePlayLogo = require('../../assets/googlePlay.png');

const playStoreLink = 'http://bit.ly/2LwkahO';
const appleStoreLink = 'https://apple.co/2Lbog2M';

class Download extends PureComponent {
  componentWillMount() {
    if (isMobile && isAndroid) {
      return window.open(playStoreLink, '_blank');
    } if (isMobile && isIOS) {
      window.location.href = 'https://itunes.apple.com/br/app/ibats/id1407292068?mt=8';
      return window.location.href;
    }

    return null;
  }

  bubbleText() {
    return (
      <Grid>
        <Grid.Row>
          <p style={{
            ...styles.bubbleText,
            ...{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }
          }}
          >
          Se você não for redirecionado automaticamente para a App Store ou Google Play Store, utilize o link abaixo para baixar o aplicativo:
          </p>
        </Grid.Row>
        <Grid.Row>
          <Image
            href={appleStoreLink}
            src={appleStoreLogo}
            size="small"
            centered
          />
        </Grid.Row>
        <Grid.Row>
          <Image
            href={playStoreLink}
            src={googlePlayLogo}
            size="small"
            centered
            style={{ marginTop: 11 }}
          />
        </Grid.Row>

      </Grid>
    );
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>ibats - Download do App</title>
        </Helmet>
        <DefaultPage>
          <Grid.Row
            centered
            verticalAlign="middle"
          >
            <Grid.Column
              verticalAlign="middle"
              computer={4}
              largescreen={4}
              mobile={14}
              tablet={8}
            >
              <Header
                as="h1"
                style={styles.title}
              >
              bem vindo ao ibats!
              </Header>

              <Segment
                // circular
                compact
              >
                {this.bubbleText()}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </DefaultPage>
      </Fragment>

    );
  }
}

export default Download;
