const constraints = {
  name: {
    presence: {
      message: '^O seu nome não pode estar em branco',
    },
    length: {
      minimum: 3,
      message: '^O seu nome precisa ter mais do que 3 caracteres',
    },
  },
  email: {
    email: {
      message: '^Email inválido',
    },
    presence: {
      message: '^Seu email não pode estar em branco',
    },
  },
  estabilishmentName: {
    presence: {
      message: '^O nome do seu estabelecimento não pode estar em branco',
    },
    length: {
      minimum: 2,
      message: '^O nome do seu estabelecimento precisa ter mais do que 2 caracteres',
    },
  },
  estabilishmentType: {
    presence: {
      message: '^O tipo do seu estabelecimento não pode estar em branco',
    },
    length: {
      minimum: 3,
      message: '^O tipo do seu estabelecimento precisa ter mais do que 3 caracteres',
    },
  },
};

export default constraints;
