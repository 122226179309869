let url = 'http://191.232.194.0/API/api/';

//Verificando se o ambiente é de desenvolvimento, se for troca a URL para homologação
if (process.env.NODE_ENV === 'development') {
  url = 'http://nvg-atlantic.brazilsouth.cloudapp.azure.com/ibats/api/';
}

const callAPI = async (urlPath, params, method = 'GET', token) => fetch(`${url}/${urlPath}`, {
  method,
  mode: 'no-cors',
  headers: {
    'Content-Type': 'application/json',
    // ...(authorization && { Authorization: authorization }),
  },
  ...(method !== 'GET'
    ? {
      body: JSON.stringify(params),
    }
    : {}),
});

export async function sendProspectForm(payload) {
  const response = await callAPI(
    'Empresa/CadastroEmpresaInteressada',
    payload,
    'POST'
  );
  return response.json();
}

export async function getStates() {
  const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados/');
  return response.json();
}

export async function getCities(stateId) {
  const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`);
  return response.json();
}
