import React, { PureComponent, Fragment } from 'react';
import {
  Grid, Header, Image
} from 'semantic-ui-react';

import { Helmet } from 'react-helmet';

import DefaultPage from '../../components/DefaultPage';

import styles from './styles';

const emailSvg = require('../../assets/email.svg');
const cardSvg = require('../../assets/card.svg');
const giftSvg = require('../../assets/gift.svg');
const qrcodeSvg = require('../../assets/qrcode.svg');
const messageSvg = require('../../assets/message.svg');

const icons = [
  [{
    svg: cardSvg,
    title: 'Acumule bats com seu CPF',
    subtitle: 'No estabelecimento, é só informar seu CPF a cada visita ao estabelecimento e seus bats serão creditados automaticamente no seu cartão de fidelidade.'
  },
  {
    svg: giftSvg,
    title: 'Resgate suas Recompensas',
    subtitle: 'Completou o cartão? Fácil, fácil… É só fornecer seu CPF na próxima visita ao estabelecimento e efetuar o resgate!'
  }],
  [{
    svg: qrcodeSvg,
    title: 'Acumule bats com códigos',
    subtitle: 'Você também pode acumular bats, utilizando códigos fornecidos pelo estabelecimento. Os códigos podem ser digitados ou lidos pela câmera.'
  },
  {
    svg: messageSvg,
    title: 'Prêmios e Promoções',
    subtitle: 'Além das fidelidades, você pode participar de promoções e ganhar prêmios instatâneos dos estabelecimentos.'
  }]
];

class ConfirmacaoEmail extends PureComponent {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>ibats - Confirmação de Email</title>
        </Helmet>
        <DefaultPage>
          <Grid.Row>
            <Grid.Column
              computer={8}
              largescreen={8}
              mobile={14}
              tablet={8}
              textAlign="center"
            >
              <Image
                src={emailSvg}
                centered
                style={styles.emailSvg}
              />
              <Header as="h1" style={styles.title}>email confirmado com suceso!</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ marginBottom: 45 }}>
            <Grid.Column
              computer={12}
              largescreen={12}
              mobile={14}
              tablet={12}
              textAlign="center"
            >
              <div style={styles.divider} />
            </Grid.Column>
          </Grid.Row>
          <Grid
            stackable
          >
            {icons.map((iconRow, i) => (
              <Grid.Row
                key={i}
                columns={4}
                centered
                verticalAlign="middle"
                style={(i === 1) ? { marginBottom: 60 } : {}}
              >
                {
                iconRow.map(({ svg, title, subtitle }, index) => (
                  <Fragment key={index}>
                    <Grid.Column
                      width={1}
                      verticalAlign="middle"
                      stretched
                    >
                      <Image src={svg} floated="right" />
                    </Grid.Column>
                    <Grid.Column
                      width={4}
                      only="computer"
                    >
                      <Header as="h4" style={styles.iconTitle}>{title}</Header>
                      <p style={styles.iconSubtitle}>
                        {subtitle}
                      </p>
                    </Grid.Column>
                    <Grid.Column
                      width={4}
                      verticalAlign="middle"
                      only="mobile"
                    >
                      <Header as="h4" style={styles.iconTitle}>{title}</Header>
                      <p style={styles.iconSubtitle}>
                        {subtitle}
                      </p>
                    </Grid.Column>
                  </Fragment>
                ))
            }
              </Grid.Row>
            ))}
          </Grid>
        </DefaultPage>
      </Fragment>
    );
  }
}

export default ConfirmacaoEmail;
