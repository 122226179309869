import React, { PureComponent, Fragment } from 'react';
import {
  Grid,
  Header,
  Form,
  Button,
  Icon,
  Input,
  Message,
  Dropdown
} from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { PhoneNumberUtil } from 'google-libphonenumber';
import validate from 'validate.js';

import DefaultPage from '../../components/DefaultPage';

import {
  getStatesSaga,
  getCitiesSaga,
  sendProspectFormSaga
} from '../../actions';

import partnerFormRules from '../../lib/constraints/partnerForm';

import styles from './styles';

const formFields = [
  [{
    labelText: 'Nome Completo',
    fieldName: 'name',
  },
  {
    labelText: 'E-mail',
    fieldName: 'email'
  }],
  [{
    labelText: 'Telefone',
    fieldName: 'phone',
  },
  {
    labelText: 'Estado',
    fieldName: 'state'
  },
  {
    labelText: 'Cidade',
    fieldName: 'city'
  }],
  [{
    labelText: 'Nome do Estabelecimento',
    fieldName: 'estabilishmentName',
  },
  {
    labelText: 'Tipo de Estabelecimento',
    fieldName: 'estabilishmentType'
  }],
];

const initialState = {
  name: '',
  email: '',
  phone: '',
  state: '',
  city: '',
  estabilishmentName: '',
  estabilishmentType: '',
  loading: false,
  formStatusMessage: '',
  success: null,
};

class PartnerForm extends PureComponent {
  constructor() {
    super();
    this.state = initialState;

    this.handleInputChanges = this.handleInputChanges.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  componentWillMount() {
    const { getStates } = this.props;

    getStates();
  }

  handleInputChanges(e, { name, value }) {
    e.preventDefault();

    return this.setState({
      [name]: value
    });
  }

  handleFormStatusMessage(message, type = 'error') {
    return this.setState({
      formStatusMessage: message,
      success: type !== 'error',
      loading: false
    });
  }

  handleDropdownOnChange(e, data, fieldName) {
    e.preventDefault();

    const { value } = data;

    if (fieldName === 'state') {
      const { options } = data;
      const { getCities } = this.props;
      const { key } = options.find(state => state.value === value);

      getCities(key);
    }

    return this.setState({
      [fieldName]: value
    });
  }

  handleOnSubmit() {
    const { sendProspectForm } = this.props;
    this.setState({
      loading: true
    });

    const dataValidator = validate(this.state, partnerFormRules);

    //Fazendo a validação, caso haja qualquer erro... Vamos impedir o cadastro
    if (dataValidator) {
      const [erro] = Object.values(dataValidator)[0];
      return this.handleFormStatusMessage(erro);
    }

    const phoneUtil = PhoneNumberUtil.getInstance();
    const { phone } = this.state;

    if (!phone || phone.length < 9) {
      return this.handleFormStatusMessage('Telefone inválido');
    }

    const number = phoneUtil.parseAndKeepRawInput(phone, 'BR');
    const isValidPhoneNumber = phoneUtil.isValidNumberForRegion(number, 'BR');

    if (!isValidPhoneNumber) {
      return this.handleFormStatusMessage('Telefone inválido');
    }

    sendProspectForm(this.state);

    this.setState(initialState);
    this.handleFormStatusMessage('Entraremos em contato', 'success');
  }

  phoneMask(userInput) {
    const numbers = userInput.match(/\d/g);

    let numberLength = 0;
    if (numbers) {
      numberLength = numbers.join('').length;
    }

    if (numberLength > 10) {
      return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
    return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }

  render() {
    const {
      loading,
      success,
      formStatusMessage,
      phone
    } = this.state;

    const { states, cities } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>ibats - Seja nosso parceiro</title>
        </Helmet>
        <DefaultPage>
          <Grid.Row
            verticalAlign="middle"
          >
            <Grid.Column
              computer={8}
              mobile={15}
              textAlign="center"
            >
              <Header
                as="h1"
                style={styles.title}
                textAlign="center"
              >
              seja um parceiro ibats!
              </Header>
              <p style={styles.subtitle}>
              Preencha o formulário abaixo para receber o contato de um de nossos representantes:
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column
              computer={8}
              mobile={15}
            >
              <Form
                onSubmit={this.handleOnSubmit}
                loading={loading}
                error={((success !== null) && !success)}
                success={((success !== null) && success)}
              >
                {
                  formFields.map((i, index) => (
                    <Form.Group
                      widths="equal"
                      key={index}
                    >
                      {
                      i.map(({ labelText, fieldName }, key) => (
                        <Form.Field key={key}>
                          <label style={styles.formLabel}>{labelText}</label> {/*eslint-disable-line*/}

                          {
                            fieldName !== 'phone' && fieldName !== 'city' && fieldName !== 'state' ? (
                              <Input
                                id={fieldName}
                                name={fieldName}
                                value={this.state[fieldName]}
                                onChange={this.handleInputChanges}
                              />
                            )
                              : fieldName === 'phone'
                                ? (
                                  <MaskedInput
                                    id="phone"
                                    mask={this.phoneMask}
                                // guide
                                    value={phone}
                                    onChange={e => this.setState({ phone: e.target.value })}
                                  />
                                )
                                : (fieldName === 'state' || fieldName === 'city')
                                && (
                                  <Dropdown
                                    placeholder={fieldName === 'state' ? 'Selecione o Estado' : 'Selecione a Cidade'}
                                    fluid
                                    disabled={fieldName === 'state' ? (!states || states.length === 0) : (!cities || cities.length === 0)}
                                    search
                                    selection
                                    onChange={(e, data) => this.handleDropdownOnChange(e, data, fieldName)}
                                    options={fieldName === 'state' ? states : cities}
                                    noResultsMessage="Não encontramos nenhum resultado :/"
                                    value={this.state[fieldName]}
                                  />
                                )

                              }
                        </Form.Field>
                      ))
                    }
                    </Form.Group>
                  ))
                }
                {formStatusMessage.length > 0
                  && (
                  <Message
                    error={((success !== null) && !success)}
                    success={((success !== null) && success)}
                    header={(success && success)
                      ? 'Formulário enviado com sucesso'
                      : 'Ops! Tivemos um Erro'}
                    content={formStatusMessage}
                  />
                  )
                }
                <Form.Field style={styles.submitButtonContainer}>
                  <Button
                    animated
                    type="submit"
                    color="teal"
                    size="big"
                    circular
                  >
                    <Button.Content visible style={styles.buttonText}>Solicitar Contato</Button.Content>
                    <Button.Content hidden>
                      <Icon name="arrow right" />
                    </Button.Content>
                  </Button>
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </DefaultPage>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  states: state.ufReducer.states,
  cities: state.ufReducer.cities
});

const mapDispatchToProps = dispatch => ({
  getStates: () => dispatch(getStatesSaga()),
  getCities: stateId => dispatch(getCitiesSaga(stateId)),
  sendProspectForm: prospectInfo => dispatch(sendProspectFormSaga(prospectInfo))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerForm);
