import { all, fork } from 'redux-saga/effects';

import watchGetStatesSaga from './watchers/getStates';
import watchGetCitiesSaga from './watchers/getCities';
import watchSendProspectForm from './watchers/saveProspectForm';

export default function* root() {
  yield all([
    fork(watchGetStatesSaga),
    fork(watchGetCitiesSaga),
    fork(watchSendProspectForm),
  ]);
}
