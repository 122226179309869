const styles = {
  emailSvg: {
    paddingLeft: 20,
    paddingTop: 40
  },
  title: {
    fontFamily: 'Poppins',
    color: '#fff',
    marginTop: 45
  },
  divider: {
    height: 3,
    opacity: 0.5,
    border: '1px solid #fff'
    // border: 'solid 1 #ffffff'
  },
  iconTitle: {
    fontFamily: 'Poppins',
    color: '#fff'
  },
  iconSubtitle: {
    fontFamily: 'Poppins',
    fontSize: 14,
    color: '#fff'
  }
};

export default styles;
