const bgImage = require('../../assets/bg.png');

const styles = {
  gridContainer: {
    backgroundImage: `url(${bgImage})`,
    minHeight: '100vh',
    width: '100vw',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%'
  }
};

export default styles;
