import React, { Component, Fragment } from 'react';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router-dom';

import store, { history } from './store';

import Home from './screens/Home';
import PartnerForm from './screens/PartnerForm';
import Download from './screens/Download';
import ConfirmacaoEmail from './screens/ConfirmacaoEmail';

import 'semantic-ui-css/semantic.min.css';
// import './App.css';

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Fragment>
            <Route exact path="/" component={Home} />
            <Route path="/download" component={Download} />
            <Route path="/formulario" component={PartnerForm} />
            <Route path="/confirmacao_email" component={ConfirmacaoEmail} />
          </Fragment>
        </ConnectedRouter>
      </Provider>
    );
  }
}
