import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_CITIES_SAGA } from '../../constants';
import { setCities } from '../../actions';
import { getCities } from '../../lib/api';

function* workerGetCitiesSaga({ stateId }) {
  const citiesResponse = yield call(getCities, stateId);

  const cities = citiesResponse.map(({ nome }) => ({ value: nome, text: nome }));

  yield put(setCities(cities));
}

export default function* watchGetCities() {
  yield takeLatest(GET_CITIES_SAGA, workerGetCitiesSaga);
}
