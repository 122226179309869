import React, { PureComponent } from 'react';
import {
  Grid, Image
} from 'semantic-ui-react';

import styles from './styles';

const footerImage = require('../../assets/footerImage.png');

export default class DefaultPage extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <Grid
        padded
        centered
        style={styles.gridContainer}
      >
        {children}
        <Grid.Row
          centered
          style={{ paddingBottom: 0 }}
        >
          <Grid.Column
            computer={8}
            // widescreen={4}
            // largescreen={4}
            mobile={16}
            // tablet={8}
            stretched
            verticalAlign="bottom"
          >
            <Image
              src={footerImage}
              size="massive"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
