import { put, takeLatest, call } from 'redux-saga/effects';

import { SEND_PROSPECT_FORM_SAGA } from '../../constants';

import { sendProspectForm } from '../../lib/api';

function* workerSendProspectForm({
  name,
  email,
  phone,
  state,
  city,
  estabilishmentName,
  estabilishmentType
}) {
  const requestPayloadData = {
    Nome: name,
    Email: email,
    Telefone: phone,
    Cidade: city,
    Estado: state,
    NomeEstabelecimento: estabilishmentName,
    Tipo: estabilishmentType
  };

  const response = yield call(sendProspectForm, requestPayloadData);

  console.log(response);
}


export default function* watchSendProspectForm() {
  yield takeLatest(SEND_PROSPECT_FORM_SAGA, workerSendProspectForm);
}
