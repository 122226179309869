import {
  SET_STATES,
  SET_CITIES,
  GET_STATES_SAGA,
  GET_CITIES_SAGA,
  SEND_PROSPECT_FORM_SAGA
} from '../constants';

export function setStates(states) {
  return {
    type: SET_STATES,
    states
  };
}

export function setCities(cities) {
  return {
    type: SET_CITIES,
    cities
  };
}

//Sagas
export function getStatesSaga() {
  return {
    type: GET_STATES_SAGA
  };
}

export function getCitiesSaga(stateId) {
  return {
    type: GET_CITIES_SAGA,
    stateId
  };
}

export function sendProspectFormSaga(prospectInfo) {
  return {
    type: SEND_PROSPECT_FORM_SAGA,
    prospectInfo
  };
}
