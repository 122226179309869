import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_STATES_SAGA } from '../../constants';
import { setStates } from '../../actions';
import { getStates } from '../../lib/api';

function* workerGetStatesSaga() {
  const statesResponse = yield call(getStates);

  const states = statesResponse.map(({ id, sigla, nome }) => ({
    key: id,
    value: sigla,
    text: nome
  }));

  yield put(setStates(states));
}

export default function* watchGetStates() {
  yield takeLatest(GET_STATES_SAGA, workerGetStatesSaga);
}
