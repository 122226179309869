
const styles = {
  title: {
    fontFamily: 'Poppins',
    fontSize: '10',
    fontWeight: 'bold',
    color: '#fff'
  },
  bubbleText: {
    whiteSpace: 'pre-wrap', /* css-3 */
    // whiteSpace: '-moz-pre-wrap', /* Mozilla, since 1999 */
    // whiteSpace: '-pre-wrap', /* Opera 4-6 */
    // whiteSpace: '-o-pre-wrap', /* Opera 7 */
    wordWrap: 'break-word', /* Internet Explorer 5.5+ */
    // paddingLeft: 40,
    // paddingRight: 40,
    // marginRight: 20,
    fontFamily: 'Poppins',
    fontSize: '1.125em',
    color: '#979797'
  }
};

export default styles;
